<template>
  <div class="text-center">
    <Loader v-if="showSuccess" />      
    <div v-else>
      <div class="flex flex-row my-5">
        <Back>Go back to collections</Back>
      </div>
      <div class="lg:w-4/5 xl:w-2/3 mx-auto px-1 md:px-4">
        <LightBox :photos="photos" :size="size" />
      </div>
    </div>
  </div>
</template>

<script>
import Back from "@/components/GoBack"
import Loader from "@/components/ImageLoader"
import LightBox from "@/components/LightBox"

import { mapGetters } from "vuex";
export default {
  name: "photoGrid",
  components: {
    Back,
    LightBox,
    Loader
  },
  data() {
    return {
      collectionId: this.$route.params.collectionId,
      categoryId: this.$route.params.categoryId,
      showSuccess: false,
      size: {
        imageSize: 320,
        gw: 16,
        gh: 16,
      },
      windowWidth: window.innerWidth,
    };
  },
  computed: {
    ...mapGetters(["photos"]),
  },
  async created() {
    await this.$store.dispatch("getPhotos", {
      collectionId: this.collectionId,
      categoryId: this.categoryId,
    }).then(() => {
      this.showSuccess = true;

      setTimeout(() => {
        this.showSuccess = false;
      }, 1000);
    })

    if (this.windowWidth < 768) {
      this.size = {
        imageSize: 150,
        gw: 5,
        gh: 5,
      };
    }
  },
};
</script>

<style>
/* .gallery {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-template-rows: repeat(8, 5vw);
} */
</style>
