<template>
  <div class="lightbox">
    <stack
      :column-min-width="size.imageSize"
      :gutter-width="size.gw"
      :gutter-height="size.gh"
      monitor-images-loaded
    >
      <stack-item
        v-for="(photo, i) in photos"
        :key="photo.id"
        style="transition: transform 500ms"
      >
        <img class="cursor-pointer" :src="photo.image" @click="lbClick(i)" />
      </stack-item>
    </stack>

    <div class="lightbox__bg" v-if="show">
      <div class="lightbox__close" @click="toggle">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-5 w-5"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fill-rule="evenodd"
            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
            clip-rule="evenodd"
          />
        </svg>
      </div>
      <div
        @click.stop="prev"
        v-if="checkPrev"
        class="lightbox__arrows lightbox__arrows--prev"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M15 19l-7-7 7-7"
          />
        </svg>
      </div>
      <div class="lightbox__slider">
        <img class="lightbox__slider__img" :src="photos[current].image" />
      </div>
      <div
        @click.stop="next"
        v-if="!checkNext"
        class="lightbox__arrows lightbox__arrows--next"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M9 5l7 7-7 7"
          />
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
import { Stack, StackItem } from "vue-stack-grid";

export default {
  components: {
    Stack,
    StackItem,
  },
  props: {
    photos: {
      type: Array,
      required: true,
    },
    size: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      current: 0,
      show: false,
    };
  },
  methods: {
    lbClick(i) {
      this.current = i;
      this.show = true;
    },
    next() {
      if (this.current <= this.photos.length) {
        this.current++;
      }
    },
    prev() {
      if (this.current >= 0) {
        this.current--;
      }
    },
    hide() {
      this.show = false;
      this.current = 0;
    },
    toggle() {
      this.show = false;
    },
    onKeydown(e) {
      if (this.show) {
        switch (e.key) {
          case "ArrowRight":
            this.next();
            break;
          case "ArrowLeft":
            this.prev();
            break;
          case "ArrowDown":
          case "ArrowUp":
          case " ":
            e.preventDefault();
            break;
          case "Escape":
            this.hide();
            break;
        }
      }
    },
  },
  computed: {
    checkNext() {
      return this.current === this.photos.length - 1;
    },
    checkPrev() {
      return this.current > 0;
    },
  },
  mounted() {
    window.addEventListener('keydown', this.onKeydown)
  },
  destroyed() {
    window.removeEventListener('keydown', this.onKeydown)
  },
};
</script>

<style lang="scss">
.lightbox {
  &__bg {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(#000, 1);
  }
  &__slider {
    &__img {
      max-height: 100vh;
    }
  }
  &__arrows {
    display: flex;
    align-items: center;
    height: 100vh;
    svg {
      width: 30px;
      height: 30px;
      cursor: pointer;
      color: white;
    }
  }
  &__close {
    position: absolute;
    cursor: pointer;
    top: 10px;
    right: 10px;
    width: 30px;
    color: white;
  }
}
</style>
